import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Clip & Clove Barbershop
			</title>
			<meta name={"description"} content={"Зробіть крок у світ, де традиції зустрічаються з трендами, а кожна стрижка є символом стилю та витонченості. У Clip & Clove Barbershop ми віримо, що чудова стрижка є наріжним каменем чудового дня. Незалежно від того, готуєтеся ви до важливої ​​події чи просто бажаєте оновити свій образ, наші двері відкриті, щоб надати вам неперевершений досвід догляду."} />
			<meta property={"og:title"} content={"Clip & Clove Barbershop"} />
			<meta property={"og:description"} content={"Зробіть крок у світ, де традиції зустрічаються з трендами, а кожна стрижка є символом стилю та витонченості. У Clip & Clove Barbershop ми віримо, що чудова стрижка є наріжним каменем чудового дня. Незалежно від того, готуєтеся ви до важливої ​​події чи просто бажаєте оновити свій образ, наші двері відкриті, щоб надати вам неперевершений досвід догляду."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23>
			<Override slot="link" />
			<Override slot="box" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text1" />
		</Components.Header23>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z) center/cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 500 46px/1.2 --fontFamily-sans" color="--light">
				Ознайомтеся з нашими послугами
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				margin="0px 0px 2rem 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Класичний крій і сучасний стиль
Precision Cuts: Пристосовано до вашого особистого стилю та рис обличчя.
Сучасний стиль: від фактурного вигляду до різких, чистих ліній – досягніть ідеальної сучасної обробки.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Стригування та догляд за бородою
Детальне підстригання: Доведіть свою бороду до досконалості за допомогою наших ретельних послуг з підстригання.
Кондиціонуючі процедури: Зберігайте свою бороду здоровою та м’якою за допомогою наших спеціалізованих продуктів.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Спеціальні процедури для волосся
Масаж шкіри голови: Розслабтеся за допомогою лікувального масажу шкіри голови, який покращує здоров’я волосся.
Глибоке кондиціонування: Відновіть життєву силу за допомогою процедур, які живлять і омолоджують ваше волосся.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Користувацькі послуги кольору
Незначні відблиски: Додайте вимір за допомогою індивідуальних відблисків, які підкреслюють ваш природний колір.
Сміливі зміни кольору: Зробіть заяву про новий, яскравий колір, адаптований до ваших уподобань.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Стилі для особливих випадків
Підготовка до події: Виглядайте якнайкраще для будь-якої події за допомогою наших спеціалізованих послуг зі стилю.
Індивідуальні консультації:  Працюйте з нашими стилістами, щоб створити ідеальний образ для будь-якого випадку.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Наведений вище список – це лише короткий огляд того, що ми пропонуємо в Clip & Clove Barbershop. Ми запрошуємо вас відвідати нас і ознайомитися з повним спектром наших послуг, розроблених відповідно до ваших потреб і вподобань у догляді. Незалежно від того, чи хочете ви зберегти свій зовнішній вигляд чи змінити його, наші двері завжди відкриті, щоб надати вам найвищий рівень обслуговування.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer1 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});